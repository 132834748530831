import styled from 'styled-components';
import {useRecoilValue, useSetRecoilState} from "recoil";
import {ReactNode, useEffect} from "react";
import {closeModal, modalState} from "../../../recoil/modal";

const Modal = ({title, children}: { title?: string, children?: ReactNode }) => {
    const {show, title: stateTitle, maxWidth: stateMaxWidth, children: stateChildren} = useRecoilValue(modalState);

    useEffect(() => {
        const el = document.querySelector('body');
        if (el) el.style.overflow = show ? 'hidden' : '';
    }, [show]);

    if (!show) return (<></>);
    if (show && (!children && !stateChildren)) return (<></>);

    return (
        <ModalWrap>
            <ModalInnerWrap $maxWidth={stateMaxWidth}>
                {/*<ModalHeaderWrap>*/}
                {/*    <h2>{title && title}</h2>*/}
                {/*    <h2>{stateTitle && stateTitle}</h2>*/}
                {/*    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"*/}
                {/*         stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"*/}
                {/*         onClick={() => closeModal(setModal)}*/}
                {/*    >*/}
                {/*        <path d="M18 6 6 18"></path>*/}
                {/*        <path d="m6 6 12 12"></path>*/}
                {/*    </svg>*/}
                {/*</ModalHeaderWrap>*/}
                {stateChildren && stateChildren}
                {children && children}
            </ModalInnerWrap>
        </ModalWrap>
    );
}

const ModalWrap = styled.div`
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0 40px;
`;

const ModalInnerWrap = styled.div<{$maxWidth?: string}>`
  width: 100%;
  max-width: ${({$maxWidth}) => $maxWidth ? $maxWidth : '700px'};
  max-height: 80vh;
  background-color: #fff;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  //padding: 20px;
  gap: 20px;

  position: relative;

  & > svg {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
`

const ModalHeaderWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export default Modal;
