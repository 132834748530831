import {Route, Routes} from "react-router-dom";
import "../../assets/css/common.css";
import React, {useEffect} from "react";
import {Home} from "../home";
import ReactGA from "react-ga4";

export const App = () => {

    useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID || '');
        ReactGA.send("pageview");
    }, []);

    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
        </Routes>
    );
}
