import {atom, SetterOrUpdater} from 'recoil';
import {modalTypes} from "../types/modal";
import {alertTypes} from "../types/alert";

export const alertState = atom<alertTypes>({
    key: 'alert',
    default: {
        show: false,
        title: '',
    }
});

export const showAlert = (hook: SetterOrUpdater<any>, {title, onSubmit}: Pick<alertTypes, 'title' | 'onSubmit'>) => {
    hook({
        show: true,
        title,
        onSubmit
    });
};

export const closeAlert = (hook: SetterOrUpdater<any>) => {
    hook({
        show: false,
        title: ''
    });
};
