import axios from 'axios';
import {useMutation} from "react-query";
import {useCallback} from "react";
import {registerTypes} from "../types/register";

export const useInsertRegister = () => {
    const api = useCallback((data: registerTypes) => {
        return axios.post('/vendor/api/v1/register', data);
    }, []);

    return useMutation(
        async (data: registerTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}
