import {ChangeEvent, Dispatch, SetStateAction, useCallback} from "react";

type types = [(e?: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => void, () => boolean, () => Record<string, any>];
export const useChangeInfo = (state:any, setter: Dispatch<SetStateAction<Record<any, any>>>): types => {
    const onChangeInfo = useCallback((e?: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
        const name = e?.target.name as string;
        const value = e?.target.value as string | number;

        setter((prev) => {
            return {
                ...prev,
                [name]: {
                    ...prev[name],
                    value,
                    validate: !((prev[name].require && value === '') || (prev[name].pattern && !prev[name].pattern.test(value))),
                },
            }
        });
    }, [state]);

    const onValidate = useCallback(() => {
        const keys = Object.keys(state);
        for (let key of keys) if (!state[key].validate) return false;
        return true;
    }, [state]);

    const onTransferData = useCallback(() => {
        const keys = Object.keys(state);
        let _info = {} as Record<string, any>;
        for (let key of keys) _info[key] = state[key].value;
        return _info;
    }, [state]);

    return [onChangeInfo, onValidate, onTransferData];
}
