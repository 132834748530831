import popup from "../../../assets/images/popup.png";
import styled from "styled-components";
import Radio from "../../atoms/radio";
import Select2 from "../../atoms/select2";
import {closeModal, modalState} from "../../../recoil/modal";
import {useSetRecoilState} from "recoil";
import AF from "../../../assets/flags/AF.png";
import AL from "../../../assets/flags/AL.png";
import DZ from "../../../assets/flags/DZ.png";
import AD from "../../../assets/flags/AD.png";
import AO from "../../../assets/flags/AO.png";
import AR from "../../../assets/flags/AR.png";
import AM from "../../../assets/flags/AM.png";
import AU from "../../../assets/flags/AU.png";
import AT from "../../../assets/flags/AT.png";
import AZ from "../../../assets/flags/AZ.png";
import BS from "../../../assets/flags/BS.png";
import BH from "../../../assets/flags/BH.png";
import BD from "../../../assets/flags/BD.png";
import BB from "../../../assets/flags/BB.png";
import BY from "../../../assets/flags/BY.png";
import BE from "../../../assets/flags/BE.png";
import BZ from "../../../assets/flags/BZ.png";
import BJ from "../../../assets/flags/BJ.png";
import BT from "../../../assets/flags/BT.png";
import BO from "../../../assets/flags/BO.png";
import BW from "../../../assets/flags/BW.png";
import BR from "../../../assets/flags/BR.png";
import BN from "../../../assets/flags/BN.png";
import BG from "../../../assets/flags/BG.png";
import BF from "../../../assets/flags/BF.png";
import BI from "../../../assets/flags/BI.png";
import KH from "../../../assets/flags/KH.png";
import CM from "../../../assets/flags/CM.png";
import CA from "../../../assets/flags/CA.png";
import CV from "../../../assets/flags/CV.png";
import TD from "../../../assets/flags/TD.png";
import CL from "../../../assets/flags/CL.png";
import CN from "../../../assets/flags/CN.png";
import CO from "../../../assets/flags/CO.png";
import KM from "../../../assets/flags/KM.png";
import CR from "../../../assets/flags/CR.png";
import HR from "../../../assets/flags/HR.png";
import CU from "../../../assets/flags/CU.png";
import CY from "../../../assets/flags/CY.png";
import CZ from "../../../assets/flags/CZ.png";
import DK from "../../../assets/flags/DK.png";
import DJ from "../../../assets/flags/DJ.png";
import DM from "../../../assets/flags/DM.png";
import DO from "../../../assets/flags/DO.png";
import EC from "../../../assets/flags/EC.png";
import EG from "../../../assets/flags/EG.png";
import SV from "../../../assets/flags/SV.png";
import GQ from "../../../assets/flags/GQ.png";
import ER from "../../../assets/flags/ER.png";
import EE from "../../../assets/flags/EE.png";
import ET from "../../../assets/flags/ET.png";
import FJ from "../../../assets/flags/FJ.png";
import FI from "../../../assets/flags/FI.png";
import FR from "../../../assets/flags/FR.png";
import GA from "../../../assets/flags/GA.png";
import GM from "../../../assets/flags/GM.png";
import GE from "../../../assets/flags/GE.png";
import DE from "../../../assets/flags/DE.png";
import GH from "../../../assets/flags/GH.png";
import GR from "../../../assets/flags/GR.png";
import GD from "../../../assets/flags/GD.png";
import GT from "../../../assets/flags/GT.png";
import GN from "../../../assets/flags/GN.png";
import GW from "../../../assets/flags/GW.png";
import GY from "../../../assets/flags/GY.png";
import HT from "../../../assets/flags/HT.png";
import HN from "../../../assets/flags/HN.png";
import HU from "../../../assets/flags/HU.png";
import IS from "../../../assets/flags/IS.png";
import IN from "../../../assets/flags/IN.png";
import ID from "../../../assets/flags/ID.png";
import IR from "../../../assets/flags/IR.png";
import IQ from "../../../assets/flags/IQ.png";
import IE from "../../../assets/flags/IE.png";
import IL from "../../../assets/flags/IL.png";
import IT from "../../../assets/flags/IT.png";
import CI from "../../../assets/flags/CI.png";
import JM from "../../../assets/flags/JM.png";
import JP from "../../../assets/flags/JP.png";
import JO from "../../../assets/flags/JO.png";
import KZ from "../../../assets/flags/KZ.png";
import KE from "../../../assets/flags/KE.png";
import KI from "../../../assets/flags/KI.png";
import KW from "../../../assets/flags/KW.png";
import KG from "../../../assets/flags/KG.png";
import LA from "../../../assets/flags/LA.png";
import LV from "../../../assets/flags/LV.png";
import LB from "../../../assets/flags/LB.png";
import LS from "../../../assets/flags/LS.png";
import LR from "../../../assets/flags/LR.png";
import LY from "../../../assets/flags/LY.png";
import LI from "../../../assets/flags/LI.png";
import LT from "../../../assets/flags/LT.png";
import LU from "../../../assets/flags/LU.png";
import MK from "../../../assets/flags/MK.png";
import MG from "../../../assets/flags/MG.png";
import MW from "../../../assets/flags/MW.png";
import MY from "../../../assets/flags/MY.png";
import MV from "../../../assets/flags/MV.png";
import ML from "../../../assets/flags/ML.png";
import MT from "../../../assets/flags/MT.png";
import MH from "../../../assets/flags/MH.png";
import MR from "../../../assets/flags/MR.png";
import MU from "../../../assets/flags/MU.png";
import MX from "../../../assets/flags/MX.png";
import FM from "../../../assets/flags/FM.png";
import MD from "../../../assets/flags/MD.png";
import MC from "../../../assets/flags/MC.png";
import MN from "../../../assets/flags/MN.png";
import ME from "../../../assets/flags/ME.png";
import MA from "../../../assets/flags/MA.png";
import MZ from "../../../assets/flags/MZ.png";
import MM from "../../../assets/flags/MM.png";
import NA from "../../../assets/flags/NA.png";
import NR from "../../../assets/flags/NR.png";
import NP from "../../../assets/flags/NP.png";
import NL from "../../../assets/flags/NL.png";
import NZ from "../../../assets/flags/NZ.png";
import NI from "../../../assets/flags/NI.png";
import NE from "../../../assets/flags/NE.png";
import NG from "../../../assets/flags/NG.png";
import KP from "../../../assets/flags/KP.png";
import NO from "../../../assets/flags/NO.png";
import PK from "../../../assets/flags/PK.png";
import PW from "../../../assets/flags/PW.png";
import PA from "../../../assets/flags/PA.png";
import PG from "../../../assets/flags/PG.png";
import PY from "../../../assets/flags/PY.png";
import PE from "../../../assets/flags/PE.png";
import PH from "../../../assets/flags/PH.png";
import PL from "../../../assets/flags/PL.png";
import PT from "../../../assets/flags/PT.png";
import RO from "../../../assets/flags/RO.png";
import RU from "../../../assets/flags/RU.png";
import RW from "../../../assets/flags/RW.png";
import KN from "../../../assets/flags/KN.png";
import LC from "../../../assets/flags/LC.png";
import VC from "../../../assets/flags/VC.png";
import WS from "../../../assets/flags/WS.png";
import SM from "../../../assets/flags/SM.png";
import ST from "../../../assets/flags/ST.png";
import SA from "../../../assets/flags/SA.png";
import SN from "../../../assets/flags/SN.png";
import RS from "../../../assets/flags/RS.png";
import SC from "../../../assets/flags/SC.png";
import SL from "../../../assets/flags/SL.png";
import SG from "../../../assets/flags/SG.png";
import SK from "../../../assets/flags/SK.png";
import SB from "../../../assets/flags/SB.png";
import SO from "../../../assets/flags/SO.png";
import ZA from "../../../assets/flags/ZA.png";
import KR from "../../../assets/flags/KR.png";
import SS from "../../../assets/flags/SS.png";
import ES from "../../../assets/flags/ES.png";
import LK from "../../../assets/flags/LK.png";
import SD from "../../../assets/flags/SD.png";
import SR from "../../../assets/flags/SR.png";
import SW from "../../../assets/flags/SW.png";
import SE from "../../../assets/flags/SE.png";
import CH from "../../../assets/flags/CH.png";
import SY from "../../../assets/flags/SY.png";
import TW from "../../../assets/flags/TW.png";
import TJ from "../../../assets/flags/TJ.png";
import TZ from "../../../assets/flags/TZ.png";
import TH from "../../../assets/flags/TH.png";
import TG from "../../../assets/flags/TG.png";
import TO from "../../../assets/flags/TO.png";
import TT from "../../../assets/flags/TT.png";
import TN from "../../../assets/flags/TN.png";
import TR from "../../../assets/flags/TR.png";
import TM from "../../../assets/flags/TM.png";
import TU from "../../../assets/flags/TU.png";
import UG from "../../../assets/flags/UG.png";
import UA from "../../../assets/flags/UA.png";
import AE from "../../../assets/flags/AE.png";
import GB from "../../../assets/flags/GB.png";
import US from "../../../assets/flags/US.png";
import UY from "../../../assets/flags/UY.png";
import UZ from "../../../assets/flags/UZ.png";
import VU from "../../../assets/flags/VU.png";
import VE from "../../../assets/flags/VE.png";
import VN from "../../../assets/flags/VN.png";
import YE from "../../../assets/flags/YE.png";
import ZM from "../../../assets/flags/ZM.png";
import ZW from "../../../assets/flags/ZW.png";
import {useCallback, useState} from "react";
import {useChangeInfo} from "../../../utils/useChangeInfo";
import {useInsertRegister} from "../../../api/register";
import ReactGA from "react-ga4";

export const RegisterTemplate = () => {
    const setModal = useSetRecoilState(modalState);

    const {mutate: registerMutate} = useInsertRegister();
    const [info, setInfo] = useState<Record<string, any>>({
        profileUrl: {value: '', require: true, validate: false, pattern: false},
        type: {value: '1', require: true, validate: true, pattern: false},
        level: {value: '1', require: true, validate: true, pattern: false},
        country: {value: '', require: false, validate: true, pattern: false},
        email: {value: '', require: true, validate: false, pattern: false},
    })
    const [onChangeInfo, onValidate, onTransferData] = useChangeInfo(info, setInfo);

    const onSubmit = useCallback(() => {
        if (!onValidate()) {
            alert('Please enter all values.');
            return false;
        }

        registerMutate(onTransferData(), {
            onSuccess: () => {
                ReactGA.event({
                    category: 'click',
                    action: 'register'
                });

                alert('Received successfully.');
                window.location.reload();
            }
        })
    }, [info]);

    return (
        <Wrap>
            <RegisterHeaderWrap>
                <img src={popup} alt={'popup'}/>

                <div>
                    <p>To register for the free trial diving campaign, please follow Diving Buddy's social media at</p>
                    <a href={'https://www.instagram.com/divingbuddy.io/'} target={'_blank'}>https://www.instagram.com/divingbuddy.io/</a>
                    <p>and provide your information below.</p>

                    <input placeholder={'Enter your instagram profile url'} name={'profileUrl'} value={info.profileUrl.value} onChange={onChangeInfo}/>
                </div>

                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"
                     onClick={() => closeModal(setModal)}
                >
                    <path d="M18 6 6 18"></path>
                    <path d="m6 6 12 12"></path>
                </svg>
            </RegisterHeaderWrap>

            <RegisterContentWrap>
                <RegisterLeftWrap>
                    <svg width="132" height="11" viewBox="0 0 132 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_6_585)">
                            <path d="M131.25 0H129.094L125.248 4.76578L121.465 0H119.309L124.36 6.37561V10.4915H126.199V6.30765L131.25 0Z"
                                  fill="#2E9FFF"/>
                            <path
                                d="M101.108 10.4958H94.1636V8.62257H101.108C102.801 8.62257 104.178 7.22087 104.178 5.49636V5.00789C104.178 3.28337 102.801 1.88167 101.108 1.88167H94.1636V0H101.108C103.815 0 106.021 2.24272 106.021 5.00364V5.49211C106.021 8.24879 103.819 10.4958 101.108 10.4958Z"
                                fill="#2E9FFF"/>
                            <path
                                d="M114.713 10.4998H107.769V8.62663H114.713C116.406 8.62663 117.783 7.22493 117.783 5.50041V5.01194C117.783 3.28743 116.406 1.88573 114.713 1.88573H107.769V0.00830078H114.713C117.42 0.00830078 119.626 2.25102 119.626 5.01194V5.50041C119.626 8.25709 117.424 10.5041 114.713 10.5041V10.4998Z"
                                fill="#2E9FFF"/>
                            <path
                                d="M59.5754 4.34951V6.2142V10.4915H58.6912H52.5518C49.845 10.4915 47.6387 8.24879 47.6387 5.48786V4.99939C47.6387 2.24697 49.845 0 52.5518 0H59.4962V1.87318H52.5518C50.8585 1.87318 49.4822 3.27488 49.4822 4.99939V5.48786C49.4822 7.21238 50.8585 8.61408 52.5518 8.61408H57.6652V6.20995H54.4871V4.34527H59.5754V4.34951Z"
                                fill="#2E9FFF"/>
                            <path d="M44.1894 0V8.55036L36.2399 0H35.8646H34.0544V10.4958H35.8646V1.87743L44.1894 10.4958H45.9954V0H44.1894Z"
                                  fill="#2E9FFF"/>
                            <path
                                d="M78.7732 3.08388C78.7732 1.38486 77.4011 0.00439453 75.7161 0.00439453H66.8198V1.88607H75.7161C76.3917 1.88607 76.9381 2.42126 76.9381 3.08388C76.9381 3.74651 76.3917 4.2817 75.7161 4.2817H66.8198V6.21859H75.7161C76.3917 6.21859 76.9381 6.75379 76.9381 7.41641C76.9381 8.07903 76.3917 8.61423 75.7161 8.61423H66.8198V10.4959H75.7161C77.4011 10.4959 78.7732 9.11544 78.7732 7.41641C78.7732 6.57114 78.4354 5.80658 77.889 5.25015C78.4354 4.69372 78.7732 3.92915 78.7732 3.08388Z"
                                fill="#2E9FFF"/>
                            <path
                                d="M87.3443 10.4958H85.651C82.8065 10.4958 80.4875 8.12986 80.4875 5.22451V0H82.3143V5.22027C82.3143 7.09769 83.8117 8.62682 85.651 8.62682H87.3443C89.1836 8.62682 90.6809 7.09769 90.6809 5.22027V0H92.5077V5.22027C92.5077 8.12561 90.1929 10.4915 87.3443 10.4915V10.4958Z"
                                fill="#2E9FFF"/>
                            <path
                                d="M6.94433 -0.000293917H0V1.87289H6.94433C8.63767 1.87289 10.014 3.27459 10.014 4.9991V5.48757C10.014 7.21208 8.63767 8.61378 6.94433 8.61378H0V10.487H6.94433C9.65116 10.487 11.8575 8.24424 11.8575 5.48332V4.99485C11.8575 2.23818 9.65533 -0.00878906 6.94433 -0.00878906V-0.000293917Z"
                                fill="#2E9FFF"/>
                            <path d="M15.34 0H13.5007V10.4915H15.34V0Z" fill="#2E9FFF"/>
                            <path d="M32.4111 0H30.5718V10.4915H32.4111V0Z" fill="#2E9FFF"/>
                            <path d="M22.9101 10.4998L16.9834 0.00830078H19.1397L22.9226 6.55381L26.7722 0.00830078H28.9243L22.9101 10.4998Z"
                                  fill="#2E9FFF"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_6_585">
                                <rect width="131.25" height="10.5" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>

                    <div>
                        <p>Your personal data will be used to</p>
                        <p>notify you of the release of the Diving</p>
                        <p>Buddy app and to enhance your</p>
                        <p>experience throughout this website.</p>
                    </div>
                </RegisterLeftWrap>

                <RegisterRightWrap>
                    <RegisterRightItem>
                        <span>Diving Type</span>

                        <div>
                            <Radio name={'type'} id={'type1'} value={1} checked={info.type.value === '1'} onChange={onChangeInfo}>Freediving</Radio>
                            <Radio name={'type'} id={'type2'} value={2} checked={info.type.value === '2'} onChange={onChangeInfo}>Scuba Diving</Radio>
                            <Radio name={'type'} id={'type3'} value={3} checked={info.type.value === '3'} onChange={onChangeInfo}>Both</Radio>
                        </div>
                    </RegisterRightItem>

                    <RegisterRightItem>
                        <span>Diving Level</span>

                        <div>
                            <Radio name={'level'} id={'level1'} value={1} checked={info.level.value === '1'} onChange={onChangeInfo}>Trainee</Radio>
                            <Radio name={'level'} id={'level2'} value={2} checked={info.level.value === '2'} onChange={onChangeInfo}>Diver</Radio>
                            <Radio name={'level'} id={'level3'} value={3} checked={info.level.value === '3'} onChange={onChangeInfo}>Instructor</Radio>
                        </div>
                    </RegisterRightItem>

                    <Select2 name={'country'} setter={setInfo} items={[
                        {text: <><img src={AF} alt={"flag"} /> Afghanistan</>, value: 'Afghanistan'},
                        {text: <><img src={AL} alt={"flag"} /> Albania</>, value: 'Albania'},
                        {text: <><img src={DZ} alt={"flag"} /> Algeria</>, value: 'Algeria'},
                        {text: <><img src={AD} alt={"flag"} /> Andorra</>, value: 'Andorra'},
                        {text: <><img src={AO} alt={"flag"} /> Angola</>, value: 'Angola'},
                        {text: <><img src={AR} alt={"flag"} /> Argentina</>, value: 'Argentina'},
                        {text: <><img src={AM} alt={"flag"} /> Armenia</>, value: 'Armenia'},
                        {text: <><img src={AU} alt={"flag"} /> Australia</>, value: 'Australia'},
                        {text: <><img src={AT} alt={"flag"} /> Austria</>, value: 'Austria'},
                        {text: <><img src={AZ} alt={"flag"} /> Azerbaijan</>, value: 'Azerbaijan'},
                        {text: <><img src={BS} alt={"flag"} /> Bahamas</>, value: 'Bahamas'},
                        {text: <><img src={BH} alt={"flag"} /> Bahrain</>, value: 'Bahrain'},
                        {text: <><img src={BD} alt={"flag"} /> Bangladesh</>, value: 'Bangladesh'},
                        {text: <><img src={BB} alt={"flag"} /> Barbados</>, value: 'Barbados'},
                        {text: <><img src={BY} alt={"flag"} /> Belarus</>, value: 'Belarus'},
                        {text: <><img src={BE} alt={"flag"} /> Belgium</>, value: 'Belgium'},
                        {text: <><img src={BZ} alt={"flag"} /> Belize</>, value: 'Belize'},
                        {text: <><img src={BJ} alt={"flag"} /> Benin</>, value: 'Benin'},
                        {text: <><img src={BT} alt={"flag"} /> Bhutan</>, value: 'Bhutan'},
                        {text: <><img src={BO} alt={"flag"} /> Bolivia</>, value: 'Bolivia'},
                        {text: <><img src={BW} alt={"flag"} /> Botswana</>, value: 'Botswana'},
                        {text: <><img src={BR} alt={"flag"} /> Brazil</>, value: 'Brazil'},
                        {text: <><img src={BN} alt={"flag"} /> Brunei</>, value: 'Brunei'},
                        {text: <><img src={BG} alt={"flag"} /> Bulgaria</>, value: 'Bulgaria'},
                        {text: <><img src={BF} alt={"flag"} /> Burkina</>, value: 'Burkina'},
                        {text: <><img src={BI} alt={"flag"} /> Burund</>, value: 'Burund'},
                        {text: <><img src={KH} alt={"flag"} /> Cambodia</>, value: 'Cambodia'},
                        {text: <><img src={CM} alt={"flag"} /> Cameroon</>, value: 'Cameroon'},
                        {text: <><img src={CA} alt={"flag"} /> Canada</>, value: 'Canada'},
                        {text: <><img src={CV} alt={"flag"} /> Cape Verde</>, value: 'Cape Verde'},
                        {text: <><img src={TD} alt={"flag"} /> Chad</>, value: 'Chad'},
                        {text: <><img src={CL} alt={"flag"} /> Chile</>, value: 'Chile'},
                        {text: <><img src={CN} alt={"flag"} /> China</>, value: 'China'},
                        {text: <><img src={CO} alt={"flag"} /> Colombia</>, value: 'Colombia'},
                        {text: <><img src={KM} alt={"flag"} /> Comoros</>, value: 'Comoros'},
                        {text: <><img src={CR} alt={"flag"} /> Costa Rica</>, value: 'Costa Rica'},
                        {text: <><img src={HR} alt={"flag"} /> Croatia</>, value: 'Croatia'},
                        {text: <><img src={CU} alt={"flag"} /> Cuba</>, value: 'Cuba'},
                        {text: <><img src={CY} alt={"flag"} /> Cyprus</>, value: 'Cyprus'},
                        {text: <><img src={CZ} alt={"flag"} /> Czech Republic</>, value: 'Czech Republic'},
                        {text: <><img src={DK} alt={"flag"} /> Denmark</>, value: 'Denmark'},
                        {text: <><img src={DJ} alt={"flag"} /> Djibouti</>, value: 'Djibouti'},
                        {text: <><img src={DM} alt={"flag"} /> Dominica</>, value: 'Dominica'},
                        {text: <><img src={DO} alt={"flag"} /> Dominican Republic</>, value: 'Dominican Republic'},
                        {text: <><img src={EC} alt={"flag"} /> Ecuado</>, value: 'Ecuado'},
                        {text: <><img src={EG} alt={"flag"} /> Egypt</>, value: 'Egypt'},
                        {text: <><img src={SV} alt={"flag"} /> El Salvador</>, value: 'El Salvador'},
                        {text: <><img src={GQ} alt={"flag"} /> Equatorial</>, value: 'Equatorial'},
                        {text: <><img src={ER} alt={"flag"} /> Eritrea</>, value: 'Eritrea'},
                        {text: <><img src={EE} alt={"flag"} /> Estonia</>, value: 'Estonia'},
                        {text: <><img src={ET} alt={"flag"} /> Ethiopia</>, value: 'Ethiopia'},
                        {text: <><img src={FJ} alt={"flag"} /> Fiji</>, value: 'Fiji'},
                        {text: <><img src={FI} alt={"flag"} /> Finland</>, value: 'Finland'},
                        {text: <><img src={FR} alt={"flag"} /> France</>, value: 'France'},
                        {text: <><img src={GA} alt={"flag"} /> Gabon</>, value: 'Gabon'},
                        {text: <><img src={GM} alt={"flag"} /> Gambia</>, value: 'Gambia'},
                        {text: <><img src={GE} alt={"flag"} /> Georgia</>, value: 'Georgia'},
                        {text: <><img src={DE} alt={"flag"} /> Germany</>, value: 'Germany'},
                        {text: <><img src={GH} alt={"flag"} /> Ghana</>, value: 'Ghana'},
                        {text: <><img src={GR} alt={"flag"} /> Greece</>, value: 'Greece'},
                        {text: <><img src={GD} alt={"flag"} /> Grenada</>, value: 'Grenada'},
                        {text: <><img src={GT} alt={"flag"} /> Guatemala</>, value: 'Guatemala'},
                        {text: <><img src={GN} alt={"flag"} /> Guinea</>, value: 'Guinea'},
                        {text: <><img src={GW} alt={"flag"} /> Guinea Bissau</>, value: 'Guinea Bissau'},
                        {text: <><img src={GY} alt={"flag"} /> Guyana</>, value: 'Guyana'},
                        {text: <><img src={HT} alt={"flag"} /> Haiti</>, value: 'Haiti'},
                        {text: <><img src={HN} alt={"flag"} /> Honduras</>, value: 'Honduras'},
                        {text: <><img src={HU} alt={"flag"} /> Hungary</>, value: 'Hungary'},
                        {text: <><img src={IS} alt={"flag"} /> Iceland</>, value: 'Iceland'},
                        {text: <><img src={IN} alt={"flag"} /> India</>, value: 'India'},
                        {text: <><img src={ID} alt={"flag"} /> Indonesia</>, value: 'Indonesia'},
                        {text: <><img src={IR} alt={"flag"} /> Iran</>, value: 'Iran'},
                        {text: <><img src={IQ} alt={"flag"} /> Iraq</>, value: 'Iraq'},
                        {text: <><img src={IE} alt={"flag"} /> Ireland</>, value: 'Ireland'},
                        {text: <><img src={IL} alt={"flag"} /> Israel</>, value: 'Israel'},
                        {text: <><img src={IT} alt={"flag"} /> Italy</>, value: 'Italy'},
                        {text: <><img src={CI} alt={"flag"} /> Ivory Coast</>, value: 'Ivory Coast'},
                        {text: <><img src={JM} alt={"flag"} /> Jamaica</>, value: 'Jamaica'},
                        {text: <><img src={JP} alt={"flag"} /> Japan</>, value: 'Japan'},
                        {text: <><img src={JO} alt={"flag"} /> Jordan</>, value: 'Jordan'},
                        {text: <><img src={KZ} alt={"flag"} /> Kazakhstan</>, value: 'Kazakhstan'},
                        {text: <><img src={KE} alt={"flag"} /> Kenya</>, value: 'Kenya'},
                        {text: <><img src={KI} alt={"flag"} /> Kiribati</>, value: 'Kiribati'},
                        {text: <><img src={KW} alt={"flag"} /> Kuwait</>, value: 'Kuwait'},
                        {text: <><img src={KG} alt={"flag"} /> Kyrgyzstan</>, value: 'Kyrgyzstan'},
                        {text: <><img src={LA} alt={"flag"} /> Laos</>, value: 'Laos'},
                        {text: <><img src={LV} alt={"flag"} /> Latvia</>, value: 'Latvia'},
                        {text: <><img src={LB} alt={"flag"} /> Lebanon</>, value: 'Lebanon'},
                        {text: <><img src={LS} alt={"flag"} /> Lesotho</>, value: 'Lesotho'},
                        {text: <><img src={LR} alt={"flag"} /> Liberia</>, value: 'Liberia'},
                        {text: <><img src={LY} alt={"flag"} /> Libya</>, value: 'Libya'},
                        {text: <><img src={LI} alt={"flag"} /> Liechtenstein</>, value: 'Liechtenstein'},
                        {text: <><img src={LT} alt={"flag"} /> Lithuania</>, value: 'Lithuania'},
                        {text: <><img src={LU} alt={"flag"} /> Luxembourg</>, value: 'Luxembourg'},
                        {text: <><img src={MK} alt={"flag"} /> Macedonia</>, value: 'Macedonia'},
                        {text: <><img src={MG} alt={"flag"} /> Madagascar</>, value: 'Madagascar'},
                        {text: <><img src={MW} alt={"flag"} /> Malawi</>, value: 'Malawi'},
                        {text: <><img src={MY} alt={"flag"} /> Malaysia</>, value: 'Malaysia'},
                        {text: <><img src={MV} alt={"flag"} /> Maldives</>, value: 'Maldives'},
                        {text: <><img src={ML} alt={"flag"} /> Mali</>, value: 'Mali'},
                        {text: <><img src={MT} alt={"flag"} /> Malta</>, value: 'Malta'},
                        {text: <><img src={MH} alt={"flag"} /> Marshall Islands</>, value: 'Marshall Islands'},
                        {text: <><img src={MR} alt={"flag"} /> Mauritania</>, value: 'Mauritania'},
                        {text: <><img src={MU} alt={"flag"} /> Mauritius</>, value: 'Mauritius'},
                        {text: <><img src={MX} alt={"flag"} /> Mexico</>, value: 'Mexico'},
                        {text: <><img src={FM} alt={"flag"} /> Micronesia</>, value: 'Micronesia'},
                        {text: <><img src={MD} alt={"flag"} /> Moldova</>, value: 'Moldova'},
                        {text: <><img src={MC} alt={"flag"} /> Monaco</>, value: 'Monaco'},
                        {text: <><img src={MN} alt={"flag"} /> Mongolia</>, value: 'Mongolia'},
                        {text: <><img src={ME} alt={"flag"} /> Montenegro</>, value: 'Montenegro'},
                        {text: <><img src={MA} alt={"flag"} /> Morocco</>, value: 'Morocco'},
                        {text: <><img src={MZ} alt={"flag"} /> Mozambique</>, value: 'Mozambique'},
                        {text: <><img src={MM} alt={"flag"} /> Myanmar</>, value: 'Myanmar'},
                        {text: <><img src={NA} alt={"flag"} /> Namibia</>, value: 'Namibia'},
                        {text: <><img src={NR} alt={"flag"} /> Nauru</>, value: 'Nauru'},
                        {text: <><img src={NP} alt={"flag"} /> Nepal</>, value: 'Nepal'},
                        {text: <><img src={NL} alt={"flag"} /> Netherlands</>, value: 'Netherlands'},
                        {text: <><img src={NZ} alt={"flag"} /> New Zealand</>, value: 'New Zealand'},
                        {text: <><img src={NI} alt={"flag"} /> Nicaragua</>, value: 'Nicaragua'},
                        {text: <><img src={NE} alt={"flag"} /> Niger</>, value: 'Niger'},
                        {text: <><img src={NG} alt={"flag"} /> Nigeria</>, value: 'Nigeria'},
                        {text: <><img src={KP} alt={"flag"} /> North Korea</>, value: 'North Korea'},
                        {text: <><img src={NO} alt={"flag"} /> Norway</>, value: 'Norway'},
                        {text: <><img src={PK} alt={"flag"} /> Pakistan</>, value: 'Pakistan'},
                        {text: <><img src={PW} alt={"flag"} /> Palau</>, value: 'Palau'},
                        {text: <><img src={PA} alt={"flag"} /> Panama</>, value: 'Panama'},
                        {text: <><img src={PG} alt={"flag"} /> Papua New GuineaPapua</>, value: 'Papua New GuineaPapua'},
                        {text: <><img src={PY} alt={"flag"} /> Paraguay</>, value: 'Paraguay'},
                        {text: <><img src={PE} alt={"flag"} /> Peru</>, value: 'Peru'},
                        {text: <><img src={PH} alt={"flag"} /> Philippines</>, value: 'Philippines'},
                        {text: <><img src={PL} alt={"flag"} /> Poland</>, value: 'Poland'},
                        {text: <><img src={PT} alt={"flag"} /> Portugal</>, value: 'Portugal'},
                        {text: <><img src={RO} alt={"flag"} /> Romania</>, value: 'Romania'},
                        {text: <><img src={RU} alt={"flag"} /> Russia</>, value: 'Russia'},
                        {text: <><img src={RW} alt={"flag"} /> Rwanda</>, value: 'Rwanda'},
                        {text: <><img src={KN} alt={"flag"} /> Saint Kitts and Nevis</>, value: 'Saint Kitts and Nevis'},
                        {text: <><img src={LC} alt={"flag"} /> Saint Lucia</>, value: 'Saint Lucia'},
                        {text: <><img src={VC} alt={"flag"} /> Saint Vincent and the Grenadines</>, value: 'Saint Vincent and the Grenadines'},
                        {text: <><img src={WS} alt={"flag"} /> Samoa</>, value: 'Samoa'},
                        {text: <><img src={SM} alt={"flag"} /> San Marino</>, value: 'San Marino'},
                        {text: <><img src={ST} alt={"flag"} /> Sao Tome and Principe</>, value: 'Sao Tome and Principe'},
                        {text: <><img src={SA} alt={"flag"} /> Saudi Arabia</>, value: 'Saudi Arabia'},
                        {text: <><img src={SN} alt={"flag"} /> Senegal</>, value: 'Senegal'},
                        {text: <><img src={RS} alt={"flag"} /> Serbia</>, value: 'Serbia'},
                        {text: <><img src={SC} alt={"flag"} /> Seychelles</>, value: 'Seychelles'},
                        {text: <><img src={SL} alt={"flag"} /> Sierra Leone</>, value: 'Sierra Leone'},
                        {text: <><img src={SG} alt={"flag"} /> Singapore</>, value: 'Singapore'},
                        {text: <><img src={SK} alt={"flag"} /> Slovakia</>, value: 'Slovakia'},
                        {text: <><img src={SB} alt={"flag"} /> Solomon Islands</>, value: 'Solomon Islands'},
                        {text: <><img src={SO} alt={"flag"} /> Somalia</>, value: 'Somalia'},
                        {text: <><img src={ZA} alt={"flag"} /> South Africa</>, value: 'South Africa'},
                        {text: <><img src={KR} alt={"flag"} /> South Korea</>, value: 'South Korea'},
                        {text: <><img src={SS} alt={"flag"} /> South Sudan</>, value: 'South Sudan'},
                        {text: <><img src={ES} alt={"flag"} /> Spain</>, value: 'Spain'},
                        {text: <><img src={LK} alt={"flag"} /> Sri Lanka</>, value: 'Sri Lanka'},
                        {text: <><img src={SD} alt={"flag"} /> Sudan</>, value: 'Sudan'},
                        {text: <><img src={SR} alt={"flag"} /> Suriname</>, value: 'Suriname'},
                        {text: <><img src={SW} alt={"flag"} /> Swaziland</>, value: 'Swaziland'},
                        {text: <><img src={SE} alt={"flag"} /> Sweden</>, value: 'Sweden'},
                        {text: <><img src={CH} alt={"flag"} /> Switzerland</>, value: 'Switzerland'},
                        {text: <><img src={SY} alt={"flag"} /> Syria</>, value: 'Syria'},
                        {text: <><img src={TW} alt={"flag"} /> Taiwan</>, value: 'Taiwan'},
                        {text: <><img src={TJ} alt={"flag"} /> Tajikistan</>, value: 'Tajikistan'},
                        {text: <><img src={TZ} alt={"flag"} /> Tanzania</>, value: 'Tanzania'},
                        {text: <><img src={TH} alt={"flag"} /> Thailand</>, value: 'Thailand'},
                        {text: <><img src={TG} alt={"flag"} /> Togo</>, value: 'Togo'},
                        {text: <><img src={TO} alt={"flag"} /> Tonga</>, value: 'Tonga'},
                        {text: <><img src={TT} alt={"flag"} /> Trinidad and Tobago</>, value: 'Trinidad and Tobago'},
                        {text: <><img src={TN} alt={"flag"} /> Tunisia</>, value: 'Tunisia'},
                        {text: <><img src={TR} alt={"flag"} /> Turkey</>, value: 'Turkey'},
                        {text: <><img src={TM} alt={"flag"} /> Turkmenistan</>, value: 'Turkmenistan'},
                        {text: <><img src={TU} alt={"flag"} /> Tuvalu</>, value: 'Tuvalu'},
                        {text: <><img src={UG} alt={"flag"} /> Uganda</>, value: 'Uganda'},
                        {text: <><img src={UA} alt={"flag"} /> Ukraine</>, value: 'Ukraine'},
                        {text: <><img src={AE} alt={"flag"} /> United Arab Emirates</>, value: 'United Arab Emirates'},
                        {text: <><img src={GB} alt={"flag"} /> United Kingdom</>, value: 'United Kingdom'},
                        {text: <><img src={US} alt={"flag"} /> United States</>, value: 'United States'},
                        {text: <><img src={UY} alt={"flag"} /> Uruguay</>, value: 'Uruguay'},
                        {text: <><img src={UZ} alt={"flag"} /> Uzbekistan</>, value: 'Uzbekistan'},
                        {text: <><img src={VU} alt={"flag"} /> Vanuatu</>, value: 'Vanuatu'},
                        {text: <><img src={VE} alt={"flag"} /> Venezuela</>, value: 'Venezuela'},
                        {text: <><img src={VN} alt={"flag"} /> Vietnam</>, value: 'Vietnam'},
                        {text: <><img src={YE} alt={"flag"} /> Yemen</>, value: 'Yemen'},
                        {text: <><img src={ZM} alt={"flag"} /> Zambia</>, value: 'Zambia'},
                        {text: <><img src={ZW} alt={"flag"} /> Zimbabwe</>, value: 'Zimbabwe'},
                    ]} placeholder={'Search'}/>

                    <input placeholder={'Enter Your Email Address'} name={'email'} value={info.email.value} onChange={onChangeInfo}/>
                    <button type={'button'} onClick={onSubmit} id={'register'}>
                        Send
                    </button>
                </RegisterRightWrap>
            </RegisterContentWrap>
        </Wrap>
    );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`

const RegisterHeaderWrap = styled.div`
  padding: 50px 30px;
  background-color: #2E9FFF;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: relative;
  font-size: 14px;
  
  & img {
    max-width: 240px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    text-align: center;
    line-height: 34px;
    
    & > p:nth-child(1) {
      max-width: 365px;
      margin: 0 auto;
    }

    & > a {
      color: #FFCA00;
      text-decoration: underline;
    }

    & > input {
      width: 100%;
      margin-top: 35px;
      border: 1px solid #979797;
      border-radius: 5px;
      color: #979797;
      padding: 12px 10px;
      font-size: 14px;

      &::placeholder {
        color: #979797;
      }
    }
  }

  & > svg {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }

  @media screen and (max-width: 835px) {
    padding: 30px 10px;
    gap: 10px;
  }

  @media screen and (max-width: 602px) {
    & img {
      max-width: 180px;
    }
  }
`

const RegisterContentWrap = styled.div`
  display: flex;
`

const RegisterLeftWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  background-color: #1D262D;

  & > div {
    color: #ACACAC;
    margin-top: 26px;
    font-size: 10px;
  }

  @media screen and (max-width: 602px) {
    padding: 10px;
  }
`

const RegisterRightWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 30px 45px;
  flex: 1;

  & > input {
    border: 1px solid #979797;
    padding: 10px;
    border-radius: 4px;
    width: 100%;
    color: #979797;
    font-size: 14px;
    
    &::placeholder {
      color: #979797;
    }
  }

  & > button {
    background-color: #2E9FFF;
    color: #fff;
    font-size: 14px;
    padding: 10px 0;
    border-radius: 4px;
  }

  @media screen and (max-width: 835px) {
    padding: 10px;
  }

  @media screen and (max-width: 602px) {
    gap: 10px;
    padding: 10px;
  }
`

const RegisterRightItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;

  & > span {
    font-weight: 600;
    font-size: 14px;
  }

  & > div {
    display: flex;
    gap: 20px;
  }
`
