import {useEffect, useState} from "react";
import {throttle} from 'lodash';
export const useWindowScroll = () => {
    const [scroll, setScroll] = useState(0);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const handleScroll = throttle(() => {
                setScroll(window.scrollY);
            }, 200);

            window.addEventListener("scroll", handleScroll);
            handleScroll();
            return () => window.removeEventListener("scroll", handleScroll);
        } else {
            return () => window.removeEventListener("scroll", () => {
                return null
            });
        }
    }, []);

    return [scroll];
}
