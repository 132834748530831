import axios from 'axios';
import {useMutation} from "react-query";
import {useCallback} from "react";
import {inviteTypes} from "../types/invite";

export const useInsertInvite = () => {
    const api = useCallback((data: inviteTypes) => {
        return axios.post('/vendor/api/v1/invite', data);
    }, []);

    return useMutation(
        async (data: inviteTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}
