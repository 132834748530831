export const colors = {
    main: '#4169E1FF',
    black: '#000000',
    red: '#F84E28',
    white: '#FFFFFF',
    gray: '#DFDFDF',
    gray010: '#EEE',
    gray020: '#CED4DAFF',
    gray040: '#8E9AA5FF',
};

export const typography = (() => {
    const sizes = [10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 22, 24, 26, 28, 32, 40, 64, 72] as const;
    const weights = [
        ['light', 300],
        ['regular', 400],
        ['medium', 500],
        ['bold', 600],
    ] as const;

    type fontKey = `${(typeof weights)[number][0]}${(typeof sizes)[number]}`;
    const entries = sizes.flatMap((size) =>
        weights.map(([weightName, value]) => [
            `${weightName}${size}` as fontKey,
            `
                font-size: ${size}px;
                font-weight: ${value};
            `,
        ]),
    );
    const fontStyles: Record<fontKey, string> = Object.fromEntries(entries);
    return fontStyles;
})();
export type fontStyles = keyof typeof typography;
