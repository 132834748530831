import checkOn from '../../../assets/images/checkOn.svg';
import checkOff from '../../../assets/images/checkOff.svg';
import styled from 'styled-components';
import {ReactNode} from "react";

type props = {
    children?: ReactNode,
    name?: string,
    id?: string,
    flex?: number,
    value?: string | number | boolean,
    checked?: string | number | boolean,
    onChange?(): void
}

const Radio = ({children, name, id, flex, value, checked, onChange}: props) => {
    return (
        <RadioWrap flex={flex}>
            <InputWrap type={'radio'} name={name} id={id} onChange={onChange} value={value} checked={checked}/>
            <LabelWrap htmlFor={id}> {children} </LabelWrap>
        </RadioWrap>
    );
};

const RadioWrap = styled.div<props>`
  flex: ${({flex}) => flex && flex};
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const InputWrap = styled.input<props>`
  display: none;

  &:checked ~ label:before {
    background-image: url(${checkOn});
  }

  &:checked ~ label {
    color: #000;
  }
`;

const LabelWrap = styled.label<props>`
  cursor: pointer;
  color: #979797;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
  
  &:before {
    content: '';
    width: 14px;
    height: 14px;
    background-image: url(${checkOff});
  };
`

export default Radio;
