import {colors, fontStyles} from "../../../styles";
import styled from "styled-components";
import {useState, Dispatch, SetStateAction, ReactNode} from "react";
import {sizes} from "../../../types";

type props = {
    name?: string;
    items: itemProps[];
    placeholder?: string;
    size?: sizes;
    isOpen?: boolean;
    setter?: Dispatch<SetStateAction<any>>;
    fontStyle?: fontStyles;
    value?: string | number;
}

type itemProps = {
    value: string | number,
    text: ReactNode,
}

type styleProps = {
    $isOpen?: boolean;
    size?: sizes;
};

const Select = ({name, setter, items, placeholder, value, size}: props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [select, setSelect] = useState<string | number>(value ? value : '');
    const [search, setSearch] = useState('');
    const [isInit, setIsInit] = useState(false);

    return (
        <SizeSelectWrap size={size}>
            <SelectTitleWrap $isOpen={isOpen}>
                {
                    select ?
                        <span>{select}</span>
                        :
                        isInit ?
                            <SelectSearchWrap>
                                <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M12.4712 7.2351C12.4712 10.4028 9.90324 12.9707 6.73559 12.9707C3.56791 12.9707 1 10.4028 1 7.2351C1 4.06742 3.56791 1.49951 6.73559 1.49951C9.90324 1.49951 12.4712 4.06742 12.4712 7.2351Z"
                                        stroke="#979797" stroke-width="1.59658" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M13.9994 14.5004L10.79 11.291" stroke="#979797" stroke-width="1.59658" stroke-linecap="round"
                                          stroke-linejoin="round"/>
                                </svg>

                                <input value={search} onChange={(e) => setSearch(e.target.value)} placeholder={placeholder}/>
                            </SelectSearchWrap> :
                            <span onClick={() => {
                                setIsOpen(!isOpen);
                                setIsInit(!isOpen);
                            }}>Select Country</span>
                }
                {
                    select ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"
                             onClick={() => {
                                 setSelect('');
                                 setIsInit(false);
                             }}
                        >
                            <path d="M18 6 6 18"></path>
                            <path d="m6 6 12 12"></path>
                        </svg>
                        : !isOpen ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => {
                                setIsOpen(!isOpen);
                                setIsInit(!isOpen);
                            }}
                                 stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                                <path d="m6 9 6 6 6-6"></path>
                            </svg> :
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => {
                                setIsOpen(!isOpen);
                                setIsInit(!isOpen);
                            }}
                                 stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                                <path d="m18 15-6-6-6 6"></path>
                            </svg>
                }

            </SelectTitleWrap>

            {
                isOpen &&
                <SelectItemsWrap>
                    {/*{*/}
                    {/*    placeholder &&*/}
                    {/*    <SizeSelectItemWrap size={size} onClick={() => {*/}
                    {/*        setIsOpen(false);*/}
                    {/*        setSelect('');*/}
                    {/*        const value = '';*/}

                    {/*        if (setter && name) setter((prev: any) => ({*/}
                    {/*            ...prev,*/}
                    {/*            [name]: {*/}
                    {/*                ...prev[name],*/}
                    {/*                value,*/}
                    {/*                validate: !((prev[name].require && value === '') || (prev[name].pattern && !prev[name].pattern.test(value))),*/}
                    {/*            },*/}
                    {/*        }));*/}
                    {/*    }}>{placeholder}</SizeSelectItemWrap>*/}
                    {/*}*/}

                    {
                        items && items.map((i, index) => {
                            if (i.value && i.value.toString().indexOf(search) === -1) return false;

                            return (
                                <SizeSelectItemWrap key={index} $active={i.value === select} $last={items.length === (index + 1)} size={size}
                                                    onClick={() => {
                                                        setIsOpen(false);
                                                        setSelect(i.value);
                                                        const value = i.value;

                                                        if (setter && name) setter((prev: any) => ({
                                                            ...prev,
                                                            [name]: {
                                                                ...prev[name],
                                                                value,
                                                                validate: !((prev[name].require && value === '') || (prev[name].pattern && !prev[name].pattern.test(value))),
                                                            },
                                                        }));
                                                    }}>
                                    <span>{i.text}</span>
                                </SizeSelectItemWrap>
                            )
                        })
                    }
                </SelectItemsWrap>
            }
        </SizeSelectWrap>
    );
}

const SelectWrap = styled.div<styleProps>`
  position: relative;
  color: ${colors.black};
`

const SizeSelectWrap = styled(SelectWrap)<styleProps>`
  ${({size}) => {
    switch (size) {
      case sizes.sm:
        return `
            height: 42px;
          `
      default:
        return `
            height: 42px;
        `;
    }
  }};
`

const SelectTitleWrap = styled.span<styleProps>`
  background-color: ${colors.white};
  border-radius: ${({$isOpen}) => !$isOpen && '4px'};
  border-top-right-radius: ${({$isOpen}) => $isOpen && '4px'};
  border-top-left-radius: ${({$isOpen}) => $isOpen && '4px'};
  border: ${({$isOpen}) => $isOpen ? `1px solid #979797` : `1px solid #979797`};
  border-bottom-width: ${({$isOpen}) => $isOpen ? 0 : '1px'};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  white-space: nowrap;
  padding: 10px;
  height: 100%;
  cursor: pointer;
  color: #979797;
  font-size: 14px;

  @media screen and (max-width: 420px) {
    //font-size: 10px;
  }
`

const SelectSearchWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  & > input {
    width: 100%;
    border: 0;
    color: #979797;
    font-size: 14px;

    &::placeholder {
      color: #979797;
    }
  }
`

const SelectItemsWrap = styled.div`
  position: absolute;
  width: 100%;
  background-color: ${colors.white};
  max-height: 300px;
  overflow: auto;
  top: 100%;
  border: 1px solid #979797;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-width: 0;
  font-size: 14px;
`

const SelectItemWrap = styled.span<{ $active?: boolean, $last?: boolean }>`
  padding: 10px;
  cursor: pointer;
  width: 100%;
  display: flex;
  gap: 10px;
  background-color: ${({$active}) => $active ? '#f7f8f9' : ''};
  color: #979797;
  border-bottom-left-radius: ${({$last}) => $last && '4px'};
  border-bottom-right-radius: ${({$last}) => $last && '4px'};

  &:hover {
    color: #fff;
    background-color: #2e9fff;
  }
`

const SizeSelectItemWrap = styled(SelectItemWrap)<styleProps>`
  & > span {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  & > span > img {
    max-width: 28px;
  }

  ${({size}) => {
    switch (size) {
      case sizes.sm:
        return `
            height: 42px;
          `
      default:
        return `
            height: 42px;
        `;
    }
  }};
`

export default Select;
