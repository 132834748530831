import {useEffect, useState} from "react";
import {throttle} from 'lodash';
export const useWindowResize = () => {
    const [windowSize, setWindowSize] = useState<{ width: undefined | number, height: undefined | number }>({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const handleResize = throttle(() => {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }, 500);

            window.addEventListener("resize", handleResize);
            handleResize();
            return () => window.removeEventListener("resize", handleResize);
        } else {
            return () => window.removeEventListener("resize", () => {
                return null
            });
        }
    }, []);

    return [windowSize.width, windowSize.height];
}
