import axios from 'axios';
import {useMutation} from "react-query";
import {useCallback} from "react";
import {contactTypes} from "../types/contact";

export const useInsertContact = () => {
    const api = useCallback((data: contactTypes) => {
        return axios.post('/vendor/api/v1/contact', data);
    }, []);

    return useMutation(
        async (data: contactTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}
